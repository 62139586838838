import { saveAs } from 'file-saver';
import { gql, useClient } from 'urql';
import { useQueryParams } from 'use-query-params';

import { useElasticIndexPrefix } from '@/contexts';
import { mapVariables } from '@/utils';
import {
  PlayerSearchReport,
  PlayerSearchReportVariables,
} from './__generated__/PlayerSearchReport';
import { queryParams } from './queryParams';

const PLAYER_SEARCH_REPORT_QUERY = gql`
  query PlayerSearchReport(
    $accountStatus: AccountStatusEnum
    $after: String
    $before: String
    $birthDate: LocalDate
    $brand: BrandEnum
    $city: String
    $countryCode: String
    $desc: Boolean
    $email: EmailAddress
    $first: Int
    $firstName: String
    $id: String
    $indexPrefix: String
    $lastLoginFrom: OffsetDateTime
    $lastLoginIp: String
    $lastLoginIpCountryCode: String
    $lastLoginTo: OffsetDateTime
    $lastName: String
    $maxBalance: PositiveBigDecimal
    $minBalance: PositiveBigDecimal
    $orderBy: PlayerSearchOrder
    $phoneNumber: String
    $registeredFrom: OffsetDateTime
    $registeredIp: String
    $registeredIpCountryCode: String
    $registeredTo: OffsetDateTime
    $registrationStatus: RegistrationStatusFilter
    $ssn: String
    $street: String
    $zipCode: String
  ) {
    viewer {
      id
      exportedPlayers(
        accountStatus: $accountStatus
        after: $after
        before: $before
        birthDate: $birthDate
        brand: $brand
        city: $city
        countryCode: $countryCode
        desc: $desc
        email: $email
        first: $first
        firstName: $firstName
        id: $id
        indexPrefix: $indexPrefix
        lastLoginFrom: $lastLoginFrom
        lastLoginIp: $lastLoginIp
        lastLoginIpCountryCode: $lastLoginIpCountryCode
        lastLoginTo: $lastLoginTo
        lastName: $lastName
        maxBalance: $maxBalance
        minBalance: $minBalance
        orderBy: $orderBy
        phoneNumber: $phoneNumber
        registeredFrom: $registeredFrom
        registeredIp: $registeredIp
        registeredIpCountryCode: $registeredIpCountryCode
        registeredTo: $registeredTo
        registrationStatus: $registrationStatus
        ssn: $ssn
        street: $street
        zipCode: $zipCode
      ) {
        url
      }
    }
  }
`;

export default function usePlayerSearchReport() {
  const [query] = useQueryParams(queryParams);
  const client = useClient();
  const { indexPrefix } = useElasticIndexPrefix();

  const generate = async () => {
    return client
      .query<PlayerSearchReport, PlayerSearchReportVariables>(
        PLAYER_SEARCH_REPORT_QUERY,
        mapVariables({ ...query, indexPrefix }),
      )
      .toPromise()
      .then((res) => {
        const url = res.data?.viewer.exportedPlayers.url;

        if (url) {
          saveAs(url, 'players-report.csv');
        }
      });
  };

  return {
    generate,
  };
}
