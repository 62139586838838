import flag from 'country-code-emoji';
import React, { FC } from 'react';

import { Nullable } from '@/types';
import { validateCountryCode } from '@/utils/formatter/formatCountryCode';

export const CountryFlag: FC<{ countryCode?: Nullable<string> }> = ({
  countryCode,
}) => {
  if (countryCode && validateCountryCode(countryCode)) {
    return <span title={countryCode}>{flag(countryCode)}</span>;
  }

  return null;
};
