import { useMemo } from 'react';

import { Nullable } from '@/types';

type Brand = {
  code: string;
  name: string;
};

export function useBrandLabelMap(brands: Nullable<Brand[]>) {
  const brandsByCode = useMemo(() => {
    return (brands ?? []).reduce<Record<string, string>>(
      (acc, brand) => ({
        ...acc,
        [brand.code]: brand.name,
      }),
      {},
    );
  }, [brands]);

  return { brandsByCode };
}
